@import "../../../common/colors.scss";
.onboarding-form-body {
    margin: 50px;
}
.stepWraper {
    background: $white;
    border: 1px solid #ebebeb;
    padding: 32px;
    .ant-steps-navigation {
        display: inline-flex;
        padding: 0;
        vertical-align: top;
        margin-bottom: 24px;

        .ant-steps-item {
            background: $offwhite;
            padding: 10px 15px 10px 50px;
            margin-right: 10px;
            position: relative;
            height: 64px;
            text-align: left;
            max-width: 330px;
            &.ant-steps-item-wait.ant-steps-item-active,
            &.ant-steps-item-finish,
            ,
            &.ant-steps-item-process {
                background: $lightgreen;
                &::after {
                    border-left: 20px solid $lightgreen;
                }
                &::before {
                    border-left: 20px solid $white;
                }
            }

            &::after,
            &.ant-steps-item-wait::after {
                border: 0;
                border-top: 32px solid transparent;
                border-bottom: 32px solid transparent;
                border-left: 20px solid $offwhite;
                transform: none;
                position: absolute;
                right: -30px;
                top: 0;
                height: 100%;
                width: 30px;
                display: block;
                margin-top: 0;
                margin-left: 0;
                z-index: 9;
            }
            &::before,
            &.ant-steps-item-wait::before {
                border: 0;
                border-top: 32px solid transparent;
                border-bottom: 32px solid transparent;
                border-left: 20px solid $white;
                transform: none;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 30px;
                display: block;
                margin-top: 0;
                margin-left: 0;
                background-color: transparent;
            }
            &:first-child::before {
                display: none;
            }
            &:last-child::after {
                display: block;
            }
        }
        .ant-steps-item-container {
            margin-left: 0;
            padding-bottom: 0;
            display: inline-flex;
            align-items: center;
        }

        .ant-steps-item-title {
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 22px;
            letter-spacing: 0.01em;
            text-transform: capitalize;
            color: $darkgray;
        }
        .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
            color: $darkgray;
            white-space: normal;
        }
        .ant-steps-item-wait.ant-steps-item-active
            > .ant-steps-item-container
            > .ant-steps-item-content
            > .ant-steps-item-title {
            color: $white;
        }
        .ant-steps-item-icon {
            width: 40px;
            height: 40px;
            background-color: #ededed;
            border-color: transparent;
            border: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 22px;
            letter-spacing: 0.01em;
            text-transform: capitalize;
            color: #b6b6b6;
            margin-right: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
        .ant-steps-item-wait {
            .ant-steps-item-icon {
                background-color: #ededed;
                border-color: transparent;
                color: #b6b6b6;
            }
        }
        .ant-steps-item-finish,
        .ant-steps-item-active {
            .ant-steps-item-icon {
                background: $white;
                .ant-steps-icon {
                    color: $darkgray;
                }
            }
        }

        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
            color: $white;
            white-space: normal;
        }
        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
            color: $white;
            white-space: normal;
        }

        &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
            padding: 10px 15px;
        }
        &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
            padding-left: 34px;
            white-space: nowrap;
        }
    }
    &.edit {
        .ant-steps-navigation .ant-steps-item {
            max-width: 380px;
        }
    }
    .onboarding-form-body {
        margin: 0;
        > p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #5c5c5c;
            margin-bottom: 32px;
        }
        p {
            &.note {
                color: #6f6f6f;
                font-size: 14px;
                margin: 0;
                margin-top: 10px;
            }
        }
        .ant-form label {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: $darkgray;
            margin-bottom: 5px;
            display: block;
            &.tick {
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }
        .ant-checkbox + span {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #6f6f6f;
        }
        .ant-form-item {
            margin-bottom: 24px;
            &.other {
                margin-top: 10px;
            }
        }
        .addrow .ant-form-item {
            margin-bottom: 10px;
        }

        .ant-btn {
            &.close {
                background: none;
                color: $black;
                padding: 0;
                .anticon {
                    font-size: 20px;
                    margin-top: 7px;
                }
            }
        }
        .truheading{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #5c5c5c;
            margin-bottom: 32px;
            h3{
                font-style: normal;
                font-weight: normal;
                font-size: 22px;
                line-height: 32px;
                letter-spacing: 0.01em;
                color: #343434;
                margin: 0;
                margin-bottom: 10px;
            }
        }
        .brandwrprow {
            flex-wrap: nowrap;
        }
        .brandimgwrpimg {
            max-width: 150px;
        }
.brandform{
    padding-left: 32px;
}
        .brandimgdiv {
            position: relative;
            width: 150px;
            height: 150px;
            border: 1px solid #eeeeee;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img{
                max-width: 100%;
            }
           .ant-btn {
                min-height: 30px;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
}

.truheading{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5c5c5c;
    margin-bottom: 32px;
    h3{
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #343434;
        margin: 0;
        margin-bottom: 10px;
    }
}

.ck-label {
    display: none !important;
}
.ck-editor__editable {
    min-height: 220px !important;
}
@media only screen and (max-width: 1300px) {
    .stepWraper {
        .ant-steps-navigation {
            .ant-steps-item {
                padding: 10px 10px 10px 30px;
                margin-right: 10px;
                min-width: inherit;
                margin-bottom: 5px;
            }
            .ant-steps-item-title {
                font-size: 18px;
                line-height: 22px;
            }
            .ant-steps-item-icon {
                width: 30px;
                height: 30px;
                font-size: 18px;
                margin-right: 10px;
                min-width: 30px;
            }
        }
        .ant-steps-navigation.ant-steps-horizontal {
            &:not(.ant-steps-label-vertical) {
                .ant-steps-item {
                    padding-left: 30px;
                }
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .stepWraper {
        padding: 16px;
        .ant-steps-navigation {
            .ant-steps-item-title {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .stepWraper .onboarding-form-body .brandimgdiv
    {
        width: 100px;
        height: 100px;
    }
    .stepWraper .onboarding-form-body .brandwrprow {
        flex-wrap: wrap;
    }
    .stepWraper .onboarding-form-body .brandform {
        padding-left: 0;
    }
}
@media only screen and (max-width: 480px) {
    .stepWraper {
        .ant-steps-navigation {
            .ant-steps-item {
                &::after {
                    left: auto;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
                width: calc(100% - 20px);
            }
            .ant-steps-item.ant-steps-item-wait,
            .ant-steps-item.ant-steps-item-process,
            .ant-steps-item.ant-steps-item-finish {
                &::before {
                    display: none;
                }
            }
        }
        .ant-steps-horizontal.ant-steps-label-horizontal {
            .ant-steps-item-content {
                display: block;
                min-height: inherit;
                overflow: hidden;
            }
        }
        .ant-steps-navigation.ant-steps-horizontal {
            &:not(.ant-steps-label-vertical) {
                .ant-steps-item {
                    padding-left: 15px;
                }
            }
        }
    }
}
