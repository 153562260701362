@import '../common/colors.scss';
div.login-box {
    .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
            display: inline !important;
            width: 47%;
        }
    }
    .ant-tabs-ink-bar {
        width: 50% !important;
    }
}

.login-modal-box .ant-modal-header {
    display: none;
}

body {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        height: 46px;
        padding: 12px 15px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    
        line-height: 20px;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #e0e0e0;
        border-right-width: 1px !important;
    }
    .ant-input {
        border: 1px solid $midgray;
        min-height: 46px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        padding: 12px 15px;
        &:-internal-autofill-selected {
            background-color: transparent !important;
        }
        &:-webkit-autofill {
            background-color: transparent !important;
        }
    }
    .ant-form-item-has-error .ant-form-item-explain,
    .ant-form-item-has-error .ant-form-item-split {
        color: #ff4d4f;
        text-align: right;
    }
    .ant-input:focus,
    .ant-input-focused {
        border-color: transparent;
        border: 1px solid $midgray;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .ant-input:hover {
        border-color: transparent;
        border: 1px solid $midgray;
    }
    .ant-form-item textarea.ant-input {
        resize: none;
    }
    .ant-input-affix-wrapper {
        border: 1px solid $midgray;
        min-height: 46px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        padding: 12px 15px;
        &:hover {
            border-color: $midgray;
            box-shadow: none;
        }
        &:focus {
            box-shadow: none;
        }
        &.ant-input-affix-wrapper-focused {
            box-shadow: none;
        }
        .ant-input {
            min-height: 20px;
            border: 0;
        }
    }
    .ant-form-item {
        margin-bottom: 16px;
    }
    .ant-divider-horizontal {
        margin: 16px 0px 32px 0px;
        border-top: 1px solid #e7e7e7;
    }
    .ant-btn {
        background: $darkgreen;
        min-height: 50px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $white;
        border: 0;
        border-radius: 0;
        &:hover,
        &:focus {
            color: $white;
            background: $darkgreen;
        }
        &.ant-btn-primary {
            background: $darkgreen;
        }
        &.ant-btn-secondary {
            background: $offwhite;
            color: $darkgray;
            &:hover,
            &:focus {
                color: $darkgray;
                background: $offwhite;
            }
        }
        &.ant-btn-sm {
            font-size: 16px;
            min-height: 35px;
            display: inline-flex;
            align-items: center;
            &.resend-btn {
                white-space: normal;
                height: auto;
            }
        }
        .anticon {
            font-size: 18px;
            display: inline-flex;
        }
    }
    .bottomButtons {
        .ant-btn {
            min-width: 150px;
        }
    }
    .ant-checkbox-inner {
        border: 1px solid $lightgreen;
        border-radius: 0;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $lightgreen;
        border-color: $lightgreen;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $lightgreen;
    }
    .ant-checkbox-checked::after {
        border: 1px solid $lightgreen;
    }
}
.container {
    padding: 0px 15px;
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
}
.container-fluid {
    padding: 0px 16px;
    width: 100%;
    margin: 0 auto;
}
.authPage {
    min-height: 100vh;
    background-image: url(../../images/mainbg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-top: 102px;
    padding-bottom: 16px;
    .reg_wraper {
        width: 100%;
        max-width: 1000px;
        background: $white;
        padding: 50px;
        margin: 0 auto;
        &.acverfiy{
            padding: 32px;
            min-width: 500px;
            max-width: 500px;
        }
        .pagename {
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.01em;
            color: $black;
            position: relative;
            padding-bottom: 16px;
            margin-bottom: 16px;
            &::before {
                content: "";
                border-bottom: 1px solid #e7e7e7;
                width: 100%;
                height: 1px;
                max-width: 300px;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
            }
        }
        p {
            margin: 0;
            padding: 0;
            margin-bottom: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #5c5c5c;
        }
    }
    .login_wraper {
        display: inline-block;
        background: $white;
        padding: 32px;
        margin: 0 auto;
        min-width: 500px;
        max-width: 500px;
        .pagename {
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.01em;
            color: $black;
            position: relative;
            padding-bottom: 16px;
            margin-bottom: 16px;
            &::before {
                content: "";
                border-bottom: 1px solid #e7e7e7;
                width: 100%;
                height: 1px;
                max-width: 100px;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
            }
        }
        p {
            margin: 0;
            padding: 0;
            margin-bottom: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #5c5c5c;
        }
        .ant-tabs-top > .ant-tabs-nav::before {
            border: 0;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
            justify-content: center;
            margin-left: -8px;
            margin-right: -8px;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
            width: 100%;
            max-width: 446px;
        }
        .ant-tabs-tab {
            margin: 0px 8px;
            background: $offwhite;
            border-bottom: 2px solid $offwhite;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 29px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.01em;
            color: $black;
            padding: 9px 15px;
            width: 50%;
            &.ant-tabs-tab-active {
                border-bottom: 2px solid $black;
            }
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: $black;
        }
        .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active{
            color: $black;
        }
        .ant-tabs-ink-bar {
            display: none;
        }
        .ant-btn.loginbtn {
            width: 100%;
        }
        .btnparent{
            display: flex;
            justify-content: space-between;
        }
        .button-link {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            text-decoration-line: underline;
            color: #000000;
            background: none;
            border: 0;
            outline: 0;
            &.cracbtn{
                background: #f1f1f1;
                width: 100%;
                margin: 0;
                text-decoration: none;
                padding: 10px;
                margin-bottom: 16px;
                min-height: 50px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.01em;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 16px;
            }
        }
        .iconswrap {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            margin-top: 70px;
            margin-bottom: 42px;
            .ant-btn {
                width: 100%;
            }
            .icondiv {
                margin-bottom: 24px;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .container-fluid {
        padding: 0px 16px;
    }
}
@media only screen and (max-width: 767px) {
    .authPage {
        .reg_wraper {
            padding: 32px 16px;
            &.acverfiy{
                padding: 32px 16px;
            }
        }
        .login_wraper {
            padding: 32px 16px;
            .iconswrap {
                margin-top: 32px;
                margin-bottom: 0px;
            }
        }
    }
    body {
        .ant-btn {
            min-height: 42px;
            font-size: 16px;
            padding: 4px 10px;
        }
    }
    .ant-row.bottomButtons {
        justify-content: flex-end;
        .ant-btn {
            min-width: inherit;
            margin-bottom: 10px;
        }
    }
}
@media only screen and (max-width: 550px) {
    .authPage {
        .reg_wraper {
            &.acverfiy{
                min-width: 100%;
            }
        }
        .login_wraper {
            min-width: 100%;
        }
    }
    .authPage .login_wraper .btnparent {
        display: block;
    }
    .authPage .login_wraper .button-link {
        line-height: 30px;       
        display: block;
    }
    body .bottomButtons .ant-btn {
        min-width: auto;
    }
    body .ant-btn {
        font-size: 14px;
        min-height: 36px;
    }
}
