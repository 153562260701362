@import "../../common/colors.scss";
.listpro {
    padding: 0px 32px;
    padding-bottom: 32px;

    .imgD {
        img {
            width: 100%;
        }
    }
    .procontent {
        margin-bottom: 16px;
        h3 {
            margin: 0;
            padding: 24px 0px;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: $black;
            background: url(../../../images/pro-line.png) no-repeat right bottom;
            margin-bottom: 10px;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: $black;
        }
        .ant-btn {
            width: 100%;

            position: relative;
            white-space: normal;
            height: inherit;
            padding: 10px;
            padding-right: 24px;
            .anticon {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.quotewraper {
    background: $white;
    border: 1px solid #ebebeb;
    padding: 32px;
    margin-bottom: 32px;
    .ant-form label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #343434;
        margin-bottom: 5px;
        display: block;
    }
}
.contectwraper {
    border: 1px solid #ebebeb;
    background: #ffffff;
    .heading {
        padding: 24px 32px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        h3 {
            margin: 0;
            padding: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.01em;
        }
    }
    .owners {
        padding: 0px 32px;
        padding-bottom: 32px;

        .imgD {
            img {
                width: 100%;
            }
        }
        .ownersdetail {
            margin-bottom: 16px;
            padding-top: 16px;
            h3 {
                margin: 0;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 34px;
                letter-spacing: 0.01em;
                color: $darkgray;
            }
            h5 {
                margin: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 34px;
                letter-spacing: 0.01em;
                color: $darkgray;
            }
            a {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 34px;
                letter-spacing: 0.01em;
                color: $darkgray;
                display: block;
                .anticon {
                    margin-right: 5px;
                }
                &:hover {
                    color: $black;
                }
            }
            .contact-no {
                pointer-events: none;
            }
        }
    }
}

.myaccount {
    border: 1px solid #ebebeb;
    background: #ffffff;
    padding: 0px 32px;
    margin-bottom: 32px;
    .myprofile {
        .prflrow {
            margin-bottom: 32px;
            flex-wrap: nowrap;
        }
        .heading {
            padding: 24px 0px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $black;
            border-bottom: 1px solid #e7e7e7;
            margin-bottom: 24px;
            h3 {
                margin: 0;
                padding: 0;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: 0.01em;
            }
        }
        .profileimg {
            max-width: 150px;
            .imgdiv {
                position: relative;
                width: 150px;
                height: 150px;
                border: 1px solid #eeeeee;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img {
                    max-width: 100%;
                }
                .ant-btn {
                    min-height: 30px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
                .ant-upload.ant-upload-select-picture-card {
                    width: 100%;
                    height: 100%;
                    margin-right: 0;
                    margin-bottom: 0;
                    background-color: transparent;
                    border: 0;
                }
                .ant-upload-picture-card-wrapper {
                    width: 30px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    height: 30px;
                }
                .ant-upload.ant-upload-select-picture-card > .ant-upload {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                }
            }
        }
        .prflcont {
            padding-left: 32px;
            max-width: 640px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #343434;
            label {
                font-weight: 500;
                font-size: 14px;
                margin: 0;
                opacity: 0.5;
                display: block;
            }
            .ant-form-item {
                font-size: 16px;
            }
            a {
                color: #343434;
            }
        }
    }
}
.changepass {
    border: 1px solid #ebebeb;
    background: #ffffff;
    padding: 0px 32px;
    .changepassword {
        padding-bottom: 32px;
        .heading {
            padding: 24px 0px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $black;
            border-bottom: 1px solid #e7e7e7;
            margin-bottom: 24px;
            h3 {
                margin: 0;
                padding: 0;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 0.01em;
            }
        }
    }
}
.documents {
    border: 1px solid #ebebeb;
    background: #ffffff;
    padding: 0px 32px;
    margin-bottom: 32px;
    padding-bottom: 16px;
    &.supplychaindoc {
        padding-top: 32px;
    }
    h3 {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.01em;
        padding: 24px 0px;
    }
    .ant-upload-picture-card-wrapper {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding-bottom: 16px;
    }
    .imgdiv {
        width: 100%;
        overflow: hidden;
        border: 1px solid #e7e7e7;
        margin-bottom: 16px;
        position: relative;
        .img_wrap {
            min-height: 200px;
            max-height: 250px;
            width: 100%;
            overflow: hidden;
            padding: 30px 0px;
            img {
                width: 100%;
                transition: all 0.2s ease-in-out;
                max-width: 100%;
            }
        }
        h5 {
            margin: 0;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            max-height: 55px;
            overflow: hidden;
            min-height: 55px;
            background: #f5f5f5;
            padding: 10px 5px;
            align-items: center;
            justify-content: center;
            display: flex;
        }

        &.files {
            padding: 0;
            img {
                width: auto;
                max-width: 100%;
            }
            .img_wrap {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &.photo {
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 5;
                background: #000;
                opacity: 0;
                transition: all 0.2s ease-in-out;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
                &::before {
                    opacity: 0.5;
                }
            }
            .img_wrap {
                padding: 0;
            }
        }
        .btnwrp {
            position: absolute;
            right: 0;
            bottom: 0;
            display: flex;
            z-index: 9;
            background-color: rgba(161, 201, 164, 0.9);
            padding: 1px;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            transform: scale(0);
            transition: all 0.2s ease-in-out;
            .ant-btn {
                margin-left: 1px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: inherit;
                background: #fff;
                color: #343434;
                .anticon {
                    font-size: 20px;
                    display: inline-flex;
                }
                &:hover {
                    background: #44514e;
                    color: #fff;
                }
            }
        }
        &:hover {
            .btnwrp {
                transform: scale(1);
            }
        }
    }
    .ant-upload {
        width: 100%;
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
        height: 100%;
        min-height: 250px;
    }
    &.step-doc {
        .ant-upload {
            min-height: inherit;
        }
    }
    .ant-upload.ant-upload-select {
        .ant-upload {
            width: 100%;
            padding: 0;
            .ant-btn {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border: 1px solid #e7e7e7;
                margin-bottom: 16px;
                background: #fafafa;
                margin: 0;
                .anticon {
                    font-size: 70px;
                    color: #d9d9d9;
                }
            }
        }
    }

    &.addfileinform {
        padding: 0;
        border: 0;
        margin: 0;
        .ant-upload.ant-upload-select .ant-upload .ant-btn {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border: 0;
            margin-bottom: 16px;
            background: #44514e;
            margin: 0;
        }
        .ant-upload.ant-upload-select .ant-upload .ant-btn .anticon {
            font-size: 18px;
            color: #fff;
        }
        .ant-upload {
            width: 100%;
            border: 0;
            padding: 0;
            margin: 0;
            background-color: transparent;
            text-align: left;
            height: auto;
            margin-bottom: 16px;
        }
        .ant-upload.ant-upload-select .ant-upload {
            width: auto;
            padding: 0;
            margin: 0;
            height: auto;
            line-height: normal;
            display: inline-block;
            min-height: auto;
        }
    }
}
.store_documents {
    border: 0;
    background: #ffffff;
    padding: 0px 32px;
    margin-bottom: 0;
    padding-bottom: 16px;
    .imgdiv {
        width: 100%;
        overflow: hidden;
        border: 1px solid #e7e7e7;
        margin-bottom: 16px;
        position: relative;
        .img_wrap {
            min-height: 200px;
            max-height: 200px;
            width: 100%;
            overflow: hidden;
            padding: 30px 0px;
            img {
                width: 100%;
                transition: all 0.2s ease-in-out;
                max-width: 100%;
            }
        }
        .download_link {
            margin: 0;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            max-height: 55px;
            overflow: hidden;
            min-height: 55px;
            background: #f5f5f5;
            padding: 10px 5px;
            align-items: center;
            justify-content: center;
            display: flex;
            color: #343434;
            .anticon {
                margin-right: 5px;
                font-size: 16px;
            }
        }

        &.files {
            padding: 0;
            img {
                width: auto;
                max-width: 100%;
            }
            .img_wrap {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
.ant-modal {
    &.confirm-model {
        .ant-modal-header {
            padding-bottom: 0;
            padding-top: 32px;
            border: 0;
        }
        .ant-modal-body {
            h3 {
                margin-left: -20px;
            }
            padding: 24px;
            padding-top: 16px;
            padding-left: 48px;
            padding-bottom: 0;
        }

        .ant-modal-footer {
            padding-top: 0;
            border: 0;
            padding-bottom: 16px;
            .ant-btn {
                min-height: 36px;
                font-size: 16px;
            }
        }
        .ant-modal-close {
            display: none;
        }
    }
}
.ant-modal-body {
    padding: 1px;
}
.ant-modal-close-x {
    display: block;
    font-size: 22px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    margin: 10px;
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26px;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 90px !important;
    width: 60px;
}
.image-gallery-icon:hover {
    color: #a1c9a4 !important;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 30px !important;
    width: 30px !important;
}
@media only screen and (max-width: 991px) {
    .listpro {
        padding: 0px 16px;
        padding-bottom: 32px;
    }
    .contectwraper .heading {
        padding: 16px;
    }
    .contectwraper .owners {
        padding: 0px 16px;
        padding-bottom: 16px;
    }
    .quotewraper {
        padding: 16px;
        margin-bottom: 16px;
    }
    .myaccount {
        padding: 0px 16px;
        margin-bottom: 16px;
    }
    .changepass {
        padding: 0px 16px;
    }
}

@media only screen and (max-width: 600px) {
    .myaccount .myprofile .profileimg .imgdiv {
        width: 100px;
        height: 100px;
    }
    .myaccount .myprofile .prflcont {
        padding-left: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .myaccount .myprofile .profileimg .imgdiv {
        width: 150px;
        height: 150px;
        margin-bottom: 16px;
    }
    .myaccount .myprofile .prflcont {
        padding-left: 0;
    }
    .myaccount .myprofile .prflrow {
        margin-bottom: 16px;
        flex-wrap: wrap;
    }
}
