
$lightgreen:#A1C9A4;
$darkgreen:#44514E;
$darkgray:#343434;
$white:#ffffff;
$offwhite:#f9f9f9;
$lightgray:#F6F6F6;
$midgray:#e0e0e0;
$black:#000000;


