#pg-14-3 .icon-box-items-wrap.short .icon-wrap.simple > span {
  font-size: 76px;
}
#pg-14-3 .icon-box-items-wrap.short .item-title,
#pg-14-3 .icon-box-items-wrap.short .description {
  padding-left: 90px;
}
.popupaoc-button {
  padding: 10px 30px !important;
  background: #44514e;
  color: #fff;
  display: inline-block;
  text-decoration: none !important;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.popupaoc-link {
  text-decoration: none !important;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.popupaoc-button:hover,
.popupaoc-button:focus {
  background: #44514e;
  color: #fff;
}
button.btn-c1,
.btn.btn-c1,
input.btn-c1[type="submit"],
.post-password-form input[type="submit"] {
  background: #44514e;
  color: #fff;
}

html,
body,
p {
  font-family: "Euclid Circular A" !important;
}

.landing_container {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  padding: 0px 16px;
  font-family: "Euclid Circular A";
}

.landing_wrapper {
  background: #a0c8a5;
  width: 100%;
  min-height: 100vh;
  font-family: "Euclid Circular A";
  line-height: normal;
  p {
    padding: 0 0 12px 0;
    margin: 0;
  }
}

.landing_logo {
  margin-top: 0px;
  display: inline-block;
}
.landing_logo img {
  max-width: 100px;
}

.landing_raw_1 {
  display: flex;
  margin-top: 85px;
}
.landing_raw_1 .colum_1 {
  width: 54%;
  padding-right: 32px;
}
.landing_raw_1 .colum_1 .text_wrap {
  padding: 16px;
  background: #fff;
  padding-bottom: 32px;
  padding-top: 24px;
}
.landing_raw_1 .colum_1 .text_wrap p {
  margin: 0;
  color: #000;
  line-height: 19px;
  font-size: 16px;
  padding: 0 0 12px 0;
}
.landing_raw_1 .colum_1 .text_wrap h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  font-family: "Euclid Circular A";
  margin-top: 30px;
  line-height: 22px;
}
.landing_raw_1 .colum_2 {
  padding-left: 24px;
  color: #fff;
  width: 50%;
  max-width: 410px;
  display: flex;
  flex-direction: column;
}
.landing_raw_1 .colum_2 h1 {
  margin: 0;
  color: #fff;
  font-family: "Euclid Circular A";
  font-size: 40px;
  border-bottom: 1px solid #fff;
  padding-bottom: 16px;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 42px;
  padding-top: 10px;
}
.landing_raw_1 .colum_2 ul {
  margin: 0;
  padding: 0;
  background-size: 475px;
  padding-bottom: 20px;
  margin-bottom: 48px;
  border-bottom: 2px solid #fff;
  position: relative;
  flex: 1 0 auto;
}
.landing_raw_1 .colum_2 > p {
  margin: 0;
  padding: 0;
}
.landing_raw_1 .colum_2 ul::before {
  width: 2px;
  height: 10px;
  content: "";
  position: absolute;
  right: 5px;
  bottom: -6px;
  background: #fff;
}

.landing_raw_1 .colum_2 ul::after {
  width: 3px;
  height: 13px;
  content: "";
  position: absolute;
  right: 12px;
  bottom: -8px;
  background: #a0c8a5;
}
.landing_raw_1 .colum_2 ul li {
  padding: 5px 0px;
  display: flex;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.3rem;
  line-height: 24px;
}

.landing_raw_1 .colum_2 ul li p {
  color: #fff;
  margin: 0px;
}
.landing_raw_1 .colum_2 ul li .num {
  padding-right: 5px;
}
.landing_raw_1 .colum_2 a {
  background: #000;
  padding: 7px;
  display: block;
  text-align: center;
  width: 100%;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05rem;
  line-height: 20px;
}

.letsbreak {
  padding-top: 32px;
}
.letsbreak .lettext {
  padding: 10px;
  font-size: 14px;
  color: #000;
}
.letsbreak .lettext h3 {
  font-size: 16px;
  font-family: "Euclid Circular A";
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 20px;
}
.letsbreak .lettext p {
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.letsbreak .lettext a {
  background: #000;
  padding: 5px 10px;
  display: inline-block;
  text-align: center;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.05rem;
  margin: 16px 0px;
  margin-bottom: 7px;
}

.owners {
  margin-bottom: 32px;
  display: flex;
}
.owners .colam {
  width: 50%;
}
.owners .colam.vin {
  padding-right: 8px;
}
.owners .colam.jas {
  padding-left: 8px;
}
.owners .colam .imgd {
  width: 100%;
}
.owners .colam .imgd img {
  width: 100%;
  height: auto;
}
.owners .colam .dtl {
  font-size: 16px;
}
.owners .colam .dtl h3 {
  margin: 0;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin-top: 12px;
}
.owners .colam .dtl p {
  line-height: 18px;
  font-weight: 400;
  color: #000;
}

.ftr-btm {
  width: 100%;
  border-top: 2px solid #000;
  position: relative;
  padding: 1px;
  display: flex;
  padding-bottom: 64px;
  align-items: center;
  padding-left: 20px;
  padding-top: 5px;
}
.ftr-btm .anticon {
  font-size: 22px;
  color: #000;
}
.ftr-btm::before {
  width: 2px;
  height: 10px;
  content: "";
  position: absolute;
  right: 5px;
  top: -6px;
  background: #000;
}
.ftr-btm::after {
  width: 3px;
  height: 13px;
  content: "";
  position: absolute;
  right: 12px;
  top: -8px;
  background: #a0c8a5;
}
.ftr-btm label {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  padding-right: 15px;
  margin-top: 6px;
}
.ftr-btm a {
  margin-right: 5px;
}
.ftr-btm a img {
  width: 18px;
}
.contact-container {
  max-width: 1365px;
  margin: 32px auto;
  overflow: hidden;
  .form-wrap {
    background-color: #f2f4f4;
    padding: 60px 60px 48px 60px;
    h2 {
      line-height: 36px;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      margin: 0;
      margin-bottom: 10px;
      .primary-color {
        color: #a0c8a5;
      }
    }
    p{
      text-align: center;
      color: rgba(0,0,0,.60);
      margin: 0;
      margin-bottom: 20px;
      line-height: 24px;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
    }
    .ant-form-item{
      margin-bottom: 30px;
    }
    .ant-input {
        min-height: 60px;
        color: rgba(0,0,0,.55);
        padding: 12px 20px;
    }
    .ant-btn.ant-btn-primary{
      background: #fff;
      width: 100%;
      margin-bottom: 30px;
      border: 1px solid #e5e5e5;
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: none;
      border-radius: 2px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 14px;
      color: rgba(0,0,0,.55);
      min-height: 60px;
      font-weight: 400;
      &:hover{
        background-color: #a0c8a5;
      }
    }
    .ant-input-group-addon{
      background-color: #fff;
      border: 1px solid #e0e0e0;
      border-left: 0;
      font-size: 20px;
    }
    .anticon{
      color: #a0c8a5;
    }
  }
  .lgwrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 32px 16px;
  }
 .circle-wrap {
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: all .6s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    width:40vw;
    height: 40vw;
    border: 1px solid #c4d2c6;
    max-width: 500px;
    max-height: 500px;
    img{
      height: auto!important;
    width: 100%;
    max-width: 200px;
    }
}
}
@media screen and (max-width: 767px) {
  .landing_raw_1 {
    flex-wrap: wrap;
  }

  .landing_raw_1 .colum_1 {
    width: 100%;
    padding-right: 0;
  }

  .landing_raw_1 .colum_2 {
    width: 100%;
    padding-left: 0;
    padding-top: 24px;
  }

  .landing_logo {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .landing_raw_1 .colum_2 h1 {
    font-size: 30px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .letsbreak {
    padding-top: 32px;
  }

  .owners {
    margin-bottom: 16px;
  }
  .mainrow{
    flex-direction: column-reverse;
  }
  .contact-container .circle-wrap {
    width: 300px;
    height: 300px;
}
}

.landingpage header {
  display: none;
}

.landingpage .site-footer {
  display: none;
}

.community header {
  display: none;
}

.community .site-footer {
  display: none;
}

.landingpage .container {
  width: 100%;
  padding: 0px;
}

.community .circle-wrap {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  transition: all 0.6s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid #c4d2c6;
}

.community .circle-wrap img {
  max-width: 200px;
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  border: none !important;
}

.header_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px;
}

.header_wrap .ftr-btm {
  width: auto;
  border: 0px;
  padding: 0px;
}
.header_wrap .ftr-btm:before {
  display: none;
}
@media only screen and (max-width: 767px) {
  .contact-container .form-wrap {
    padding: 16px;
}
.contact-container .form-wrap h2 {
  font-size: 24px;
}
.contact-container .form-wrap .ant-form-item {
  margin-bottom: 16px;
}
}